<template>
  <div>
    <div class="background">
      <Top />
      <div class="title">付费类别:活动</div>
      <div class="query">活动选择</div>
      <div class="btn">
        <button
          :class="v.select ? 'btn1' : 'btn2'"
          @click="fun(v, i)"
          v-for="(v, i) in arr"
          :key="i"
        >
          {{ v.name }}
        </button>
      </div>
      <!-- <div class="query">请选择参加人数:</div> -->
      <div class="input">
        <b class="buyNum">购买数量</b>
        <div class="buyRight">
          <button @click="del()">-</button
          ><input type="text" class="value" v-model="value" readonly /><button
            @click="add()"
          >
            +
          </button>
        </div>
      </div>

      <div class="bottom">
        <div class="money">
          <p class="moneyPay">支付金额:</p>
          <span>￥{{ money / 100 }}&nbsp;元</span>
        </div>
        <div class="quxiao" @click="cancelPay()">取消</div>
        <div class="zhifu" @click="pay()">支付</div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/top.vue";
import { getlink } from "@/api/getapi.js";
import { postlink } from "@/api/postapi.js";
export default {
  data() {
    return {
      money: 0,
      value: 1,
      zhi: "",
      query: 0,
      arr: [],
      current: [],
      id: "",
      sourceId: "",
      nums: "",
      type: 1,
      orderNo: "",
      // [
      //   {1:"活动",
      //   2:"讲解",
      //   3:"展览",
      //   4:"物料"}
      // ],
      name: "",
    };
  },
  components: {
    Top,
  },
  mounted() {
    getlink("https://zmnh.bowu66.cn/h5/findActivityList").then((ok) => {
      console.log(ok.data.data);
      let newData = ok.data.data;
      // this.arr = newData
      // this.id=ok.data.data.id
      // console.log(this.id)
      this.arr = newData.map((item) => {
        item.select = false;
        return item;
      });
    });
  },
  methods: {
    cancelPay() {
      this.$router.go(-1);
    },
    fun(v) {
      // v.select=!v.select
      this.arr = this.arr.map((item) => {
        item.select = false;
        if (v.id == item.id) {
          item.select = true;
          this.money = item.price;
          this.name = item.name;
        }

        return item;
      });

      // console.log(v.select)
      this.value = 1;
      this.current = v;
      this.id = v.id;
      console.log(this.id);
    },
    add() {
      this.value++;
      this.arr = this.arr.map((item) => {
        item.select = false;
        if (this.current.id == item.id) {
          item.select = true;
          this.money = item.price * this.value;
        }
        return item;
      });
    },
    del() {
      if (this.value > 1) {
        this.value--;
      }
      this.arr = this.arr.map((item) => {
        item.select = false;
        if (this.current.id == item.id) {
          item.select = true;
          this.money = item.price * this.value;
        }
        return item;
      });
    },
    pay() {
      console.log(this.id)
       console.log(this.value)
        console.log(this.type)
      postlink("https://zmnh.bowu66.cn/h5/confirmOrder", {
        sourceId: this.id,
        nums: this.value,
        type: this.type,
      }).then((ok) => {
        console.log(ok);
        this.$router.push({
          name: "confirm",
          query: {
            sourceId: this.id,
            nums: this.value,
            name: this.name,
            money: this.money,
            orderNo: ok.data.data.orderNo,
            type: this.type,
          },
        });
      });
    },
  },
};
</script>

<style scoped>
.background {
  background-color: #f4f4f4;
  height: 7rem;
}
.title {
  font-size: 0.16rem;
  /* border-left: #589167 8px solid; */
  margin-top: 0.2rem;
  text-indent: 1em;
  margin-left: 5vw;
  width: 90vw;
  height: 0.55rem;
  background-color: white;
  border-radius: 0.1rem;
  font-weight: 800;
}
.query {
  font-size: 0.16rem;
  /* color: silver; */
  margin-top: 0.2rem;
  margin-left: 6vw;
  font-weight: 800;
}
.btn {
  width: 80vw;
  height: 35vh;
  background-color: white;
  margin: auto;
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.3rem;
  padding-top: 0.2rem;
  align-items: center;
  font-size: 0.16rem;
  /* text-align: center; */
  box-shadow: 0.02rem 0.02rem 0.05rem #888888;
  border-radius: 0.05rem;
  overflow-y: auto;

  /* justify-content: center; */
}
.input {
  width: 88%;
  height: 10vh;
  background-color: white;
  font-size: 0.16rem;
  display: flex;
  margin: auto;
  align-items: center;
  box-shadow: 0.02rem 0.02rem 0.05rem #888888;
  border-radius: 0.05rem;
  margin-top: 0.2rem;
  justify-content: space-around;
}
.buyNum {
  width: 120px;
}

.input button {
  width: 5vh;
  height: 5vh;
  background-color: #6fb6b6;
  border: none;
  font-size: 0.16rem;
  border-radius: 0.05rem;
  margin-left: 0.05rem;
  margin-right: 0.05rem;
}
.input .div {
  width: 55vw;
  height: 5vh;
  margin-left: 45vw;
}
.value {
  width: 15vw;
  height: 4vh;
  outline: none;
  border: 0.01rem skyblue solid;
  font-size: 0.18rem;
  text-align: center;
}
.btn1 {
  width: 60%;
  height: 8vh;
  /* background-color:; */
  border-radius: 0.1rem;
  line-height: 8vh;
  background-color: #6fb6b6;
  border: 0.01rem rgb(219, 217, 217) solid;
  margin: 0.1rem;
}
.btn2 {
  width: 60%;
  height: 8vh;
  /* background-color: springgreen; */
  border-radius: 0.1rem;
  line-height: 8vh;
  /* border: 0.01rem rgb(88, 88, 88) solid; */
  border: skyblue 0.02rem solid;
  border-radius: 0.1rem;
  margin: 10px;
}

.inp {
  width: 100%;
  height: 5vh;
  /* background-color: skyblue; */
  font-size: 0.16rem;
  line-height: 5vh;
  font-family: "宋体";
  margin-top: 0.1rem;
  margin-left: 0rem;
  text-indent: 1em;
  text-indent: 0;
}
button {
  background-color: white;
  outline: none;
}
.money {
  height: 6vh;
  border-radius: 0.05rem;
  margin: auto;
  margin-top: 0.1rem;
  font-size: 0.16rem;
  display: flex;
  line-height: 8vh;
  justify-content: space-around;
  text-indent: 0;
  background-color: white;
  line-height: 6vh;
  margin-left: 0.2rem;
}
.moneyPay {
  width: 1rem;
}
.money span {
  font-size: 0.24rem;
  line-height: 6vh;
  /* text-align: right; */
}
.zhifu {
  margin-top: 2vh;
  width: 40%;
  background-color: #6fb6b6;
  border-radius: 0.2rem;
  height: 5vh;
  font-size: 0.16rem;
  /* margin-left: 0.2rem; */
  text-align: center;
  line-height: 5vh;
  color: white;
  font-family: "宋体";
  /* display: flex; */
  /* line-height: 5px; */
  margin-right: 0.1rem;
}
.bottom {
  width: 100%;
  height: 8vh;
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: white;
  justify-content: space-between;
}
.bottom span {
  color: #ff7e0c;
}
.quxiao {
  width: 25%;
  height: 5vh;
  border-radius: 0.05rem;
  font-size: 0.16rem;
  /* border: yellow 1px solid; */
  line-height: 5vh;
  font-family: "宋体";
  margin-left: 0.1rem;
  margin-top: 2vh;
  text-align: center;
  color: #6fb6b6;
}
</style>